.flippingBookPage {
  min-height: 100vh;
}
.flippingBookBackground{
   position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url("/public/images/TealWavyBackground.jpg");
   background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
 

}

.flipbook{
  margin-bottom: 20vh;
}
@media (max-width: 800px) {
   .flippingPageFooter{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  }
}
@media (max-width: 768px) {
  .flipbook{
    display: flex;
    align-items: center;
    height: 50vh;
  }
 
}
@media (max-width: 500px) {
  .flippingBookBackground:before{
    content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("/public/images/TealWavyBackground.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  }
}
