.flex-end-ptac{
    display: flex;
    justify-content: flex-end;
}
.versionParagraph{
    margin-top: 1%;
}
.indent-3-percent{
    margin-left: 3%;
}

.versionParagraphScroll::-webkit-scrollbar {
  display: none;
}

.versionParagraphScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 40vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
  transform: translateZ(0);
  font-size: 0.9em;
}