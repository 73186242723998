.glossaryDefinitions::-webkit-scrollbar {
  display: none;
}
.glossaryDefinitions{
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 80vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
  transform: translateZ(0);
}