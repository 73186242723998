.settingsPage {
  min-height: 100vh;
}
.settingsBackground{
   position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url("/public/images/TealWavyBackground.jpg");
   background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;

}

@media (max-width: 500px) {
  .settingsBackground{
    background-size: auto 100%;
  }
}

.accordianWrapper{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    margin: 5%;
}

.accordian{
    min-width: 100vh;
}

.settingFooter{
  
}

@media (max-width: 1000px) {
  .accordian {
    min-width: 10vh;
    max-width: 50vh;
  }
}

@media (max-width: 400px) {
  .accordian {
    min-width: 10vh;
    max-width: 50vh;
  }
}



