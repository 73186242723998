.purple-button-box-shadow{
    border-radius: 30px;
    background-image: linear-gradient(45deg, rgb(10,0,222) , #5711b9);
    box-shadow: 5px 5px  rgba(78,9,25, 0.6);
    color: white;
    cursor: pointer;
    
}
.purple-button-box-shadow:hover {
  box-shadow: 5px 5px  rgba(78,9,25, 0.6), 0 0 20px rgba(255, 255, 255, 0.2), 0 0 30px rgba(255, 255, 255, 0.4), 0 0 40px rgba(255, 255, 255, 0.2);

}
.purple-button-box-shadow:active {
box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
transform: translateY(2px);
}


.getStartedSize{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 6vh;
    min-width: 200px;
    font-size: 1.5em !important;

}
.goBackSize{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    font-size: 1em;
}
.downloadSize{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28vh !important;
    height: 8vh;
    font-size: 1.5em;  
}

.frameworkButtonSize{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;  
}
.goBackButtonText{
    text-transform: uppercase;
    min-width: 15vh;
    max-width: 40vh;
    margin: 5%;
   
}
.purpleButtonText{
    min-width: 20vh;
    max-width: 40vh;
    margin: 4%;
    text-transform: uppercase;
   
}


@media (max-width: 899px) {


.downloadSize{
    
    width: 18vh !important;
    height: 6vh;
    font-size: 1em;
  
}
}

@media (max-height: 500px) {

.purpleButtonText{
  font-size: 0.7em;
}
.downloadSize{
    
    width: 40vh !important;
    height: 10vh;
    font-size: 1em;
  
}
}

@media (max-width: 700px){
    .getStartedSize{
        font-size: 0.8em;
    }
    
    .purpleButtonText{
      font-size: 0.8em;
    }
    .downloadSize{
      width: 40%  !important;
    }
}


.play-button {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  border: none;
  background-image: linear-gradient(45deg, rgb(10,0,222) , #2196f3);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 1px 1px #2196f3;
  
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 25px;
  border-color: transparent transparent transparent #fff;
  
}

.play-button:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
  transform: rotate(45deg) scale(0);
  transition: transform 0.3s ease-out;
}

.play-button:hover:before {
  transform: rotate(45deg) scale(1);
}