.resourcePage {
  min-height: 100vh;
}
.resourceBackground{
   position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url("/public/images/TealWavyBackground.jpg");
   background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;

}
@media (max-width: 500px) {
  .resourceBackground:before{
    content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("/public/images/TealWavyBackground.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
 
  }
}

.resourceBody{
  font-size: 1.1em;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-right: 5%;
}

.emissionGraphWrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5% 2% 0 2%;

}
.emisssionGraphLeftSideDefinition{
  width: 30%;
  font-size: 0.9em;
  text-align: justify;
  margin-right: 0.5%;
}
.emisssionGraphRightSideDefinition{
  
  width: 30%;
  font-size: 0.9em;
  text-align: justify;
}
.emisssionGraphBottomDefinitionWrapper{
  display: flex;
  justify-self: center;
  font-size: 0.9em;
  text-align: justify;
  width: 700px;
  margin-left: 8%;
 
}

@media (max-width: 1550px) {

  .emisssionGraphBottomDefinitionWrapper{
 font-size: 0.7em;
  }
  .emisssionGraphLeftSideDefinition{
    font-size: 0.7em;
  }
  .emisssionGraphRightSideDefinition{
    font-size: 0.7em;
  }

}


.graphDefinition{
  margin-top: 5%;
  margin-bottom: 5%;
}


@media (max-width: 1350px) {

  .emissionGraphWrapper{
    flex-direction: column;
  }
  .emisssionGraphBottomDefinitionWrapper{
    width: 80%;
    margin-left: 0%;
    margin-bottom: -5%;
  }
  .emisssionGraphLeftSideDefinition{
    width: 80%;
  }
  .emisssionGraphRightSideDefinition{
    width: 80%;
  }

}
@media (max-width: 500px) {

 

}

