.solutionPage {
  min-height: 100vh;
}
.solutionBackground{
   position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url("/public/images/TealWavyBackground.jpg");
   background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;

}
@media (max-width: 500px) {
  .solutionBackground:before{
    content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("/public/images/TealWavyBackground.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  }
}
.emissionSolutionContainer::-webkit-scrollbar {
  display: none;
}
.emissionSolutionContainer{
  height: 60vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overflow-y: scroll;
  transform: translateZ(0);

}

.servicesCard{
  width: 70%;
}
.directoryResultCard{
  width: 70%;
  margin-top: 5%;
  min-height: 300px;
}

.checkboxFlex{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

}

.checkboxTitle{
  margin-left: 30%;
}
.checkboxVirtualTitle{
  margin-left: 40%;
}

.directoryDisclaimer{
  color: white;
  opacity: 0.7;
  width: 75%;
  font-size: 0.8em;
  margin-left: 10%;
 
}

@media (max-width: 900px) {
   .checkboxTitle{
  margin-left: 0;
}
.checkboxVirtualTitle{
  margin-left: 0;
}
   
}
@media (max-width: 500px){
  .servicesCard{
    width: 90%;
  }
  .directoryResultCard{
    width: 90%;
  }
}
