.caseStudyPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.caseStudyBackground {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url("/public/images/TealWavyBackground.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
}
@media (max-width: 500px) {
  .caseStudyBackground{
    background-size: auto 100%;
  }
}


.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

}

.caseStudy {
  margin: 0;
}


