/*Keep styles on inputs when autofilling*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
   
}

.MuiTextField-root.loginInput input:-webkit-autofill,
.MuiTextField-root.loginInput input:-webkit-autofill:hover,
.MuiTextField-root.loginInput input:-webkit-autofill:focus,
.MuiTextField-root.loginInput input:-webkit-autofill:active {
    -webkit-text-fill-color: white;
}

/* Indentation and margins*/
.indent{
  margin-left: 5%;
}
.zero-all-around-margin{
  margin: 0;
}
.margin-around-2-percent{
  margin: 2%;
}
.margin-around-5-percent{
  margin: 5%;
}

/* Font Styles */
.white-font{
     color:white;
     opacity: 0.85;
    
}
.justifyParagraph{
  text-align: left;
  /* hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em; */
    /* word-break: break-all; */
    
}

.font-weight-300{
  font-weight: 300;
}

/* Flex Style*/
.flex-column-align{
  display: flex;
  flex-direction: column;
}
.flex-row-align{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flex-align-horizontal-vertical{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-align-base{
  display: flex;
  justify-content: center;
  align-content:  center;
}

.flex-align-end{
  display: flex;
  justify-content: end;
  align-content:  center;
}



