.equationsPage{
 min-height: 100vh;
}
.equationsBackground{
     position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url("/public/images/TealWavyBackground.jpg");
   background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
}
@media (max-width: 500px) {
  .equationsBackground:before{
     content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("/public/images/TealWavyBackground.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  }
}

.equations{
    margin-top: 3%;
}
.equationContianer{
    margin-bottom: 2%;
}
.equationTitle{
    font-size: 1.2em;
    text-decoration: underline;
}
.equation{
    font-size: 0.8em;
}