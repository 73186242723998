.termsBackground{
    position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url("/public/images/PurpleWavyBackground.jpg");
   background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;

}


.policyContent::-webkit-scrollbar {
  display: none;
}

.policyContent {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 40vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
  transform: translateZ(0);
  font-size: 0.9em;
}