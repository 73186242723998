.homePage {
  min-height: 100vh;
}
.homeBackground{
   position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url("/public/images/TealWavyBackground.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
 
}

@media (max-width: 500px) {
  .homeBackground:before{
   content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("/public/images/TealWavyBackground.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
 
}

}




.homeContent{
 margin-top: 5%;

}
.indentSection{
  margin-left: 15%;
}
.assetSection{
   display: flex;
   flex-direction: column;
  
}
.assetButton{
  margin: 10% 0 0 20%;
}


.assetTitle{
    font-size: 3em;
    margin-top: -7%;
}
.assetBody{
    padding: 3%;
    width: 81%;
    font-size: 1.3em;

}



.capabilitySection{
    margin-bottom: 2%;
}

.capabilityTitle{
  font-size: 2em;
  margin-bottom: 3%;
}

.capabilityContainer{
   width: 70%;
    
}

.capabilityBody::-webkit-scrollbar {
  display: none;
}

.capabilityBody {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 40vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
  transform: translateZ(0);
  font-size: 0.9em;
}

.secondSectionHeader{
  font-size: 2em;
  margin-top: 15%;
}
.metroImage{
  margin-bottom: 5%;
}

.helpIndustryHeader{
  display: flex;
  justify-content: center;
  margin-top: 3%;
  font-size: 2em;
  
}
.helpIndustryText{
  width: 60%;
}
.helpIndustryPoints{
  margin-top: 10%;
  margin-left: 20%;
  font-size: 1.2em;

}

.widePurpleImageText{
  font-size: 2.5em;
  margin-bottom: -2%;
}


.entireInfoCardWrapper::-webkit-scrollbar {
  display: none;
}

.entireInfoCardWrapper{
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 45vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
  transform: translateZ(0);
}
.infoCardContainer{
  width: 75%;
}

.homeResouceSection{
  margin-top: 5%;
  width: 80%;
}
.homeTankEmissionsSection{
 background-image: url("/public/images/PurpleWavyBackground.jpg");
 background-size: 100% 100%;

  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  margin-top: 5%;
  margin-bottom: 1%;
  

}

.padding-top-10{
  padding-top: 5%;
}

.mobileButtonGroup{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15%;
}

.resourceTool{
 margin: 1.5% 5% 0 0;
}

@media (max-width: 1200px){
  .entireInfoCardWrapper{
    height: 65vh;
  }
}

@media (max-width: 899px) {
  .indentSection{
  margin-left: 10%;
}
.secondSectionHeader{
  margin-top: 0;
}
  
  .assetSection {
    margin-bottom: 10%;
    width: 100%;
  }
  .assetBody{
    width: 50%;
  }
  .capabilitySection{
    margin-bottom: 10%;
  }
  .widePurpleImageText{
    font-size: 1.5em;
  }
  .assetButton{
  margin: 0% 0 0 0%;
}


}

@media (max-width: 500px){
  .assetBody{
    width: 80%;
  }
  .helpIndustryHeader{
    font-size: 1.5em;
    
  }
  .helpIndustryText{
    width: 80%;
  }
  .homeResouceSection{
    margin-left: -10% ;
    width: 100%;
  }
  .resourceTool{
    margin-left: 0;
  }
  .widePurpleImageText{
    font-size: 1em;
   
  }
  .mobileButtonGroup{
    gap: 3%;

  }
}

