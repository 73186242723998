.loginPage{
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

.loginMain {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url("/public/images/BlueParticleBackground.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh; 
  width: 100%;
}
@media (max-width: 500px) {
  .loginMain{
    background-size: auto 100%;
  }
}
@media (max-width: 500px) {
  .loginMain{
    background-size: auto 100%;
  }
}





.ptacLogoContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 2%;
  width: 90%;
}
.loginPtacLogo{
  margin-top: 30px ;
}
.cerinLogo{
 margin-top: -30px;
 margin-right: -5%;
}
@media (max-width: 900px){
  .loginPtacLogo{
    margin-top: 35px ;
  }
  .cerinLogo{
    margin-top: -10px;
  }
}

@media (max-width: 600px){
  .loginPtacLogo{
  margin-top: 30px ;
}
  .cerinLogo{
    margin-top: -5px;
  }
}
.ptacLogoLogin{
  margin-top: 2%;
}
.loginTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2% 5% 0 5% !important;
}
.bold-white-font {
  color: white;
  font-weight: 700;
}
.loginTitle {
  text-align: center;
  font-size: 2.5em;
}

.loginSubTitle {
   text-transform: uppercase;
  font-size: 1.5em !important;
  margin-bottom: 2% !important;

}
.loginContainer{
 width: 35% !important;

}


@media (max-height: 715px) {
  .loginSwitch{
    margin-left: 4% !important;
  }
  .loginContainer{
    width: 80%;
  }

}


.inputContainer {
  padding-top: 5%;
  background-color: #201734;
  border-radius: 20px;
  box-shadow: 10px 10px  rgba(0, 0, 0, 0.4);
 
}
.resetContainer{
  display: flex;
  justify-content: flex-end;
  width: 85%;
}

.resetButton{
  font-size: 0.5em;
  justify-self: flex-end;
}

.loginInputWrapper{
  margin-left: 15%;
  margin-bottom: 5%;
  
}
.inputSwitchContainer{
  display: flex;
  flex-direction: row;

  justify-content: center;
}
.emailPasswordWrapper{
  flex-basis: 70%;

}

.loginInput {
  width: 80%;
  background-color: #50515c;
  height: 40px;
}

.bold-400-white {
  color: white;
  font-weight: 400;
 
}

.loginButton {
  background-image: linear-gradient(to right, #c5ccc9 60%, #50515c);
  color: black !important;
  width: 50% !important;
  height: 40px;
  border-radius: 20px;
  margin: 4% 2% 2% 2% !important;
  font-size: 1.2em;
  background-size: 150% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-in-out;
}


.loginButton:hover {
  background-image: linear-gradient(to right, #3dc5eb 10%, #05389b 90%);
 background-position: left bottom;
}

@keyframes gradientTransition {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.loginSwitch {
  transform: rotate(90deg);
  height: 0;
  margin-top: 90px;
  margin-right: -20% !important;
  margin-right: -20% !important;
}

@media (max-width: 900px) {
  .loginSwitch{
    margin-left: 4% !important;
  }
  .loginContainer{
    width: 75% !important;
  
  }

}

@media (max-height: 750px) {
  .loginTitle{
    font-size: 1.5em !important;
  }

}

@media (max-width: 500px) {
  .ptacLogoContainer{
    flex-direction: column;
  }
}



@media (max-width: 400px) {
  .loginTitleContainer .loginTitle {
    font-size: 1.5em;
  }
  .loginContainer{
    width: 90% !important;
  }
 
  .loginSwitch{
    margin-right: -30% !important;
    margin-left: 2%  !important;
  }
  /* .emailPasswordWrapper{
    margin-left: 40%;
  } */

}






.signUpButton{
  border-radius: 10px;
  background-color: #14a298;
  color: white;  
  transition: all .3s ease-in-out;
  width: 100px;
}

.cerinButton {
  border-radius: 10px;
  margin-top: 5%;
  margin-left: 24%;
  background-color: #d8871c;
  color: white;  
  transition: all .3s ease-in-out;
  width: 140px;
}

.signUpButton:hover{
  background-color: rgb(15.75,124.5,117);
}
.signUpText{
  font-size: 1.2em !important;
  margin: 5% 3% 5% 3%;
}

.signUpContainer{
  margin-top: 3%;
}

.loginFooter{
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 50px !important;

}
@media (max-height: 500px) {
  .signUpContainer{
    margin-bottom: 100px !important;
  }
}