.loginPage{
  background-image: url("/public/images/BlueParticleBackground.jpg");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  height: 110vh;
}
@media (max-width: 500px) {
  .loginPage:before{
     content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("/public/images/TealWavyBackground.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  }
}


.loginMain {
  
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}



.ptacLogoContainer {
  display: flex;
  align-self: flex-start;
  margin-left: 2%;
  margin-top: 2%;
}
.loginTitleContainer {
  margin: 5% 5% 0 5%;
}
.bold-white-font {
  color: white;
  font-weight: 700;
}
.loginTitle {
  font-size: 2.5em;
}

.loginSubTitle {
  font-size: 2em;
  margin: 5%;
}
.loginContainer{
 width: 35%;

}

@media (max-width: 900px) {
  .loginContainer {
    width: 70%;
  }
}


.inputContainer {
  padding-top: 5%;
  background-color: #201734;
  border-radius: 20px;
  box-shadow: 10px 10px  rgba(0, 0, 0, 0.4);
 
}

.loginInputWrapper{
  margin-left: 15%;
  
}
.inputSwitchContainer{
  display: flex;
  flex-direction: row;
  

  
}
.emailPasswordWrapper{
  flex-basis: 70%;

}

.loginInput {
  
  width: 80%;
  background-color: #50515c;
}

.bold-400-white {
  color: white;
  font-weight: 400;
 
}

.loginButton {
  background-image: linear-gradient(to right, #c5ccc9 60%, #50515c);
  color: white;
  width: 50%;
  height: 40px;
  border-radius: 20px;
  margin: 2%;
  font-size: 1.2em;

}

.loginSwitch {

  transform: rotate(90deg);
  margin-left: -2%;
  margin-right: -5%;
  margin-bottom: 5%;

  
}
@media (max-width: 1300px) {
  .loginSwitch {
    margin-left: -10%;
  }
}

@media (max-width: 400px) {
  .loginSwitch {
    margin-left: -30%;
  }
}

.signUpButton{
 
  border-radius: 10px;
  
  background-color: #14a298;
  color: white;  
  transition: all .3s ease-in-out;
}

.signUpButton:hover{
  background-color: rgb(15.75,124.5,117);
}
.signUpText{
font-size: 15px;

}

