

.footerFlexBox{
    border-top: 1px solid white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 2px;
    margin-top: auto;
}
.footerCompanyName{
   display: flex;
    margin-left: auto;
    margin-right: 1%;
}

.footerText{
 opacity: 0.8;
font-style: italic;
margin-top: 15px;
margin-bottom: 5px;
}

.logoContainer{
    display: flex;
    align-items: center;
    justify-content: space-between; 
}
.footerSocialMediaLogos{
    width: 30px;
}

.footerBWVantageLogo{

    width: 150px;
    justify-self: end;
  
}
.breakWaterIconButton{
    height: 20px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    
}
.bwLogoContainer{
    
}

@media (max-width: 600px) {
    .footerBWVantageLogo{
        width: 100px;
    }
    .footerText{
        font-size: 0.8em;
    }
}

