.registerPage{
  min-height: 100vh;
}
.registerMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url("/public/images/TealWavyBackground.jpg");
  /* background-color: #010221; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
}
@media (max-width: 500px) {
  .registerMain{
    background-size: auto 100%;
  }
}

.registerContainer {
border-radius: 20px;
height: 100%;
width: 60%;
position: relative; /* Add a relative position to the container */
margin-top: 3%;
}

.registerContainer::before {
  border-radius: 20px;
content: "";
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
background-image: url("/public/images/registerBg.jpg");
background-size: 100% 100%;
background-repeat: no-repeat;
background-position: center center;
background-attachment: fixed;
opacity: 0.9;
z-index: -1; /* Set a negative z-index to position the overlay behind the content */
}
.flex-center-column {
   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.termContainer{
  margin-top: 5%;
  margin-bottom: -5%;
}

.registerInputWrapper{
  margin-top: 5%;
}
.registerInput {
  min-width: 300px !important;
  max-width: 300px;
  height: 70px;
}
.errorMessage {
  color: red;
}

.textMargin {
  margin-top: 12px;
}

@media (max-width: 1100px) {
  .registerInput{
  min-width: 200px !important;
  max-width: 200px;
   height: 100%;
  }

}

@media (max-width: 800px) {
  
  .registerContainer {
    width: 90%;
}

}
