
@media (max-width: 500px) {
.carouselCaption{
    margin-left: 12%;
font-size: 0.7em;
width: 60%;
}

 

}

.rec-arrow {
    
background-color: rgb(46,8,206) !important;
   
}

.rec-dot{
  background-color: rgb(46,8,206) !important;
}