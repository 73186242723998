.calculatorInputsContainer{
   width: 100%;
    
}

.calculatorInputContainer{
   display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items:  baseline;
  width: 90%;
  margin: 2%;
  gap: 5%;
}
.summaryInputContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items:  baseline;
    margin-bottom: 3%;
    margin-top: 3%;
    gap: 2%;
}

.calculatorInput{
    width: 100%;
    max-width: 200px;
    min-width: 200px;
}

.summaryInput{
   width: 150px;
}
.summaryLabel{
margin-right: 5px;
}
.openEndedLinesInput{
    width: 100%;
    max-width: 234px;
    min-width: 200px;
}


.sectionCard{
    background-image: linear-gradient(45deg, rgba(245,244,253, 1) 1%, rgba(188,180,216, 0.8) 90%);
    border-radius: 20px;
    box-shadow: 10px 10px rgba(0,94,136, 0.4);
}

.versionCard{
    background-image: linear-gradient(45deg, rgba(245,244,253, 1) 1%, rgba(188,180,216, 0.8) 90%);
    border-radius: 10px;
    box-shadow: 10px 10px rgba(0,94,136, 0.4);
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.versionHistoryText{
    margin: 2% 5% 2% 5%;
    font-size: 0.8em;
}
.flex-column-horizontal-vertical-align{
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.versionHistoryContainer{
   
  width: 105vh;
  display: flex;
  justify-content: flex-end;
}

.sectionSubTitle{
    display: flex;
    align-items: center;
    margin: 2% 0 0 5%;
    font-size: 1.7em;
    padding-top: 2%;
}

.summaryInputs{
    background-color: rgba(245,244,253, 0.8);
    margin: 5% 5% 5% 10%;
    border-radius: 20px;
    padding-top: 1%;
    width: 85%;
}

.fugitiveSummaryInputs{
    background-color: rgba(245,244,253, 0.8);
    margin: 20% 5% 5% 10%;
    border-radius: 20px;
    padding-top: 1%;
}
.summaryTitle{
    margin: 5% 0 0 5%;
    font-size: 1.1em;
}

.flex-row-vertical-align{
    display: flex;
    flex-direction: row;
   align-items: baseline;
}

.selectBox{
    width: 60%;
    max-width: 100px;
    margin-left: 2%;
}
.selectBoxIsolated{
    width: 100%;
    max-width: 200px;
    min-width: 200px;
}
.allCheckboxContianer{
    margin: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    float: right;
}



.commentContainer{
    margin: 0% 0 10% 10%;
}
.commentInput{
    width: 100%;
}
.subTitleText{
    font-size: 1.1em;
    margin-top: 2%;
    font-weight: 500;
}
.mapLabel{
    font-size: 1.2em;
    margin-bottom: 2%;
}
.mapContainer{
    width: 90%;
    height: 30vh;
}
.uwiSurfaceContainer{
    margin-left: 15%;
    width: 100%;
}
.eventSelectContainer{
    margin-left: 1%;
    width: 98%;
}

.uwiSelectContainer{
    margin: 2%;
    
}
.uwiLabels{
    width: 100%;
}
@media (max-width: 1400px) {
    .versionHistoryContainer{
        width: 100%;
    }
}
@media (max-width: 1150px) {
    .selectBoxIsolated{
    
    max-width: 150px;
    min-width: 150px;
}
    .calculatorInput{
        width: 150px;
    }
    .openEndedLinesInput{
        width: 90%;
        max-width: 185px;
        min-width: 150px;
    }
 
    .versionCard{
        margin-top: 5%;
    }
}


@media (max-width: 900px) {
    .calculatorInputsContainer{
        width: 80%;
    }
    .calculatorInput{
        max-width: 200px;
    min-width: 200px;
    }
    
    .openEndedLinesInput{
      max-width: 234px;
    min-width: 200px;
    }
    .mapWrapper{
       margin-left:  10%;
       margin-bottom: 5%;
        
    }
    .commentContainer{
        margin-left: 10%;
        margin-right: 18%;
    }
    .fugitiveSummaryInputs{
        margin: 5% 5% 5% 5%;
    }
   
    
}

@media (max-width: 700px) {
    .sectionCard{
      width: 70%;
      margin-left: 10%;
    }
    .summaryInputs{
        width: 100%;
    }
    
    .calculatorInputContainer{
        flex-direction: column;
        margin-left: 10%;
    }
    .versionHistoryContainer{
        width: 70%;
    }
 
}



@media (max-width: 500px) {
    .selectBoxIsolated{
        max-width: 100px;
    }
    .sectionCard{
      width: 50%;
      margin-left: 25%;
    }
    
   
    
}





