.calculationPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
 
}

.calculationBackground {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url("/public/images/TealWavyBackground.jpg");
  
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed !important;
  min-height: 100vh;
 width: 100%;
  padding-bottom: 100px; 
}
@media (max-width: 500px) {
  .calculationBackground:before{
    content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("/public/images/TealWavyBackground.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
   
  }
}


.checkBoxContainer{
     display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

}
.title{
    margin-top: 2%;
    margin-left: 20%;
    margin-bottom: -2%;
    
}
.calculatorSections{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  margin: 2% 10% 0 10%;
  width: 80%;
  
}
.section{
   
    margin-bottom: 5%;
}

.sectionTitle{
  font-size: 3em;
  margin-top: 2%;
  text-transform: uppercase;
}
.resultSection{
  
}
.resultInput{
  width: 100%;
}

@media (max-width: 1100px) {
   .resultSection{
    width: 100%;
   }
   
}
.entireInputWrapper{
  display: flex;
  flex-direction: row;
}

@media (max-width:850px) {
   .entireInputWrapper{
    flex-direction: column;
   }
   .resultInput{
    width: 300px;
   }
  
}


.resultInputContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 2%;
}


.resultLabel{
  font-size: 1.1em;
  font-weight: 600;
  margin: 2%;

}

@media (max-width: 700px) {
   .sectionTitle{
    margin-left: 15%;
   }
   .resultSection{
   margin-right: 20%;
   width: 83%;
   }
   .title{
    margin-left: 5%;
   }
   .calculateButtonGroup{
    
    margin-bottom: 40%;
    flex-direction: column;
   }
   
}

@media (max-width: 500px) {
   .sectionTitle{
    margin-left: 25%;
    margin-right: 25%;
    font-size: 2em;
   }
   .title{
     font-size: 3em;
   }
   .resultInput{
    width: 150px;
   }
   .resultMainTitle{
  font-size: 1.1em;
}
}

