.flexWrapper {


}
.innerFlexWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.increaseWidth{
   
}

.popoverText {
  padding: 5px;
}
.infoIcon {
  margin-left: 5px;
  color: grey;
  margin-bottom: -3px;
}

.infoCard {
  position: relative;
   border-radius: 20px;
  box-shadow: 10px 10px rgba(8,58,80, 0.8);
  margin-bottom: 3%;
}

.infoCardBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: -1;
}



.infoCard .infoCardTitle {
  width: 100%;
  font-size: 2em;
  color: white;
  position: relative;
  z-index: 1;
  margin: 2% 0 0 5%;
  opacity: 0.9;
}

.infoCard .infoCardBody {
  font-size: 1.5em;
  color: white;
  opacity: 0.9;
  margin-top: 5%;
  margin-left: 10%;
  margin-bottom: 5%;
  width: 75%;
}
.titleSummary{
  font-size: 1.3em;
}
.pointSummary{
  font-size: 1em;
  width: 80%;
}
.caseStudyContainer{
  margin-bottom: 150px;
}
.caseTitle{
  font-size: 3em;
  font-weight: 400;
  text-transform: uppercase;
}
.caseBody{
  width: 90%;
  font-size: 1.2em;
 
}
.caseImages{
  display: flex;
  justify-content: center;
}
.bigImage{
  margin-left: 25%;
  margin-right: 10%;
}
.smallImage{
  margin-top: 5%;
}
.caseStudyButton{

}

.companyCard{
 width: 75%;
 min-width: none;
}
.contactInfo{
  margin: 5%;
}

.glow {
 
  color:#fff;
  text-shadow:1px 1px 10px #fff, 1px 1px 10px #ccc;
 
}

/* Gets rid of arrow buttons on number inputs*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (max-width: 1313px) {
  .bigImage{
  margin-left: 25%;
  margin-right: 0;
}

}
@media (max-width: 1141px) {
  .caseImages{
    flex-direction: column;
    justify-content: center;
 
  }
  .bigImage{
  margin-left: 0;
  margin-right: 0;
}


}

@media (max-width: 1200px) {
  .infoCard .infoCardTitle{
    margin-left: 5%;
    font-size: 2.4em;
  }
  .infoCard .infoCardBody{
    margin: 5%;
    font-size: 1.4em;
  }

}

@media (max-width: 500px){
  .infoCardTitle{
    font-size: 1.5em !important;
  }
  .infoCardBody{
    font-size: 1.1em !important;
  }
  .pointSummary{
    font-size: 1em;
  }
  .companyCard{
    width: 90%;
  }
}

